<template>
  <b-row class="date-filter" no-gutters>
    <b-col
      cols="auto"
      class="pr-50"
      v-for="(button, i) in buttons"
      :key="`button-${i}`"
    >
      <n-button-loading
        :disabled="loading"
        :variant="value == button.value ? 'primary' : 'outline-primary'"
        @click="changeDate(button.value)"
      >
        {{ $t(button.label) }}
      </n-button-loading>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import NButtonLoading from "@/components/NButtonLoading";

export default {
  components: {
    BRow,
    BCol,

    NButtonLoading,
  },
  props: {
    value: {},
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      buttons: [
        {
          value: "dtd",
          label: "field.dtd",
        },
        {
          value: "wtd",
          label: "field.wtd",
        },
        {
          value: "mtd",
          label: "field.mtd",
        },
        {
          value: "ytd",
          label: "field.ytd",
        },
      ],
    };
  },
  methods: {
    changeDate(value) {
      this.$emit("input", value);
      this.$emit("change");
    },
  },
  setup() {},
};
</script>