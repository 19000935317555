<template>
  <b-card v-if="data" no-body>
    <b-overlay :show="loading" rounded="sm" variant="light">
      <b-card-header class="pb-0">
        <b-card-title>{{ $t("field.callCompletion") }}</b-card-title>
      </b-card-header>

      <b-card-body>
        <b-row>
          <b-col class="d-flex justify-content-center">
            <vue-apex-charts
              type="radialBar"
              height="270"
              :options="supportTrackerRadialBar.chartOptions"
              :series="series"
            />
          </b-col>
          <b-col
            cols="auto"
            md="auto"
            xl="auto"
            class="d-flex flex-column flex-wrap text-center justify-content-between"
          >
            <div>
              <h1 class="font-large-2 font-weight-bolder mb-0 text-primary">
                {{ data.visitedCallPlan | number }}
              </h1>
              <small>{{ $t("field.visitedOutlet") }}</small>
            </div>
            <div>
              <h1 class="font-large-2 font-weight-bolder mb-0 text-primary">
                {{ data.callPlan | number }}
              </h1>
              <small>{{ $t("field.plannedCall") }}</small>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BRow,
  BCol,
  BCardText,
  BOverlay,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BOverlay,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    series() {
      if (this.data.callPlanCompletionRate) {
        return [this.data.callPlanCompletionRate];
      }

      return [0];
    },
  },
  data() {
    return {
      supportTrackerRadialBar: {
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: "65%",
              },
              track: {
                background: "#ebf2ff",
                strokeWidth: "100%",
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: "#5e5873",
                  fontSize: "1rem",
                },
                value: {
                  offsetY: 15,
                  color: "#5e5873",
                  fontSize: "1.714rem",
                },
              },
            },
          },
          colors: [$themeColors.danger],
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: [""],
        },
      },
    };
  },
};
</script>
