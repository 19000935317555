export default [
  {
    key: 'regionIds',
    label: 'field.region',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'region',
    selectionKey: 'id',
    selectionLabel: 'regionCode',
    md: 12,
    lg: 12,
  },
  {
    key: 'salespersonIds',
    label: 'field.salesperson',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'salesperson',
    selectionKey: 'id',
    selectionLabel: 'label',
    md: 12,
    lg: 12,
  },
]
