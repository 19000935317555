<template>
  <div>
    <load-profile></load-profile>

    <b-row align-v="center" class="mb-1">
      <b-col>
        <date-filter v-model="query.period" @change="onChangeDate" />
      </b-col>
      <b-col cols="12" md="auto" class="text-right mt-1 mt-md-0">
        <b-button variant="light" size="sm" @click="showFilter">
          <feather-icon icon="FilterIcon" size="18" />
        </b-button>
      </b-col>
    </b-row>

    <h3 class="mb-1">
      {{ query.fromDate | formatDate("LL") }}
      <span v-if="query.period != 'dtd'">
        -
        {{ query.toDate | formatDate("LL") }}
      </span>
    </h3>
    <b-row>
      <b-col cols="12">
        <sales-volume-card
          v-model="query.state"
          :states="states"
          :data="data"
          :loading="loading"
          @change="index"
        />
      </b-col>

      <b-col cols="12">
        <unscheduled-order-card :loading="loading" :data="data" />
      </b-col>

      <b-col md="6">
        <call-completion-card :loading="loading" :data="data" />
      </b-col>
      <b-col md="6">
        <effective-call-card :loading="loading" :data="data" />
      </b-col>

      <filter-modal ref="filterModal" @search="onSearch" />
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import CallCompletionCard from "./CallCompletionCard.vue";
import EffectiveCallCard from "./EffectiveCallCard.vue";
import UnscheduledOrderCard from "./UnscheduledOrderCard.vue";
import SalesVolumeCard from "./SalesVolumeCard.vue";
import DateFilter from "./DateFilter";
import NButtonLoading from "@/components/NButtonLoading";
import { calculateDatesByPeriod } from "@/libs/helper";
import FilterModal from "./filterModal/Index.vue";

const DashboardRepository = Repository.get("dashboard");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BButton,

    CallCompletionCard,
    EffectiveCallCard,
    UnscheduledOrderCard,
    SalesVolumeCard,
    DateFilter,
    NButtonLoading,
    FilterModal,
  },
  data() {
    return {
      loading: false,
      query: {
        fromDate: this.$route.query.fromDate || null,
        toDate: this.$route.query.toDate || null,
        period: this.$route.query.period || "dtd",
        state: this.$route.query.state || "all",
        salespersonIds: this.$route.query.salespersonIds || [],
        regionIds: this.$route.query.regionIds || [],
      },
      states: ["all", "complete", "progress"],
      data: {},
    };
  },
  created() {
    if (!this.query.fromDate && !this.query.toDate) {
      this.calculateDate();
    }
    this.index();
  },
  methods: {
    calculateDate() {
      let type = "today";
      if (this.query.period == "ytd") {
        type = "thisYear";
      } else if (this.query.period == "mtd") {
        type = "thisMonth";
      } else if (this.query.period == "wtd") {
        type = "thisWeek";
      }
      const dates = calculateDatesByPeriod(type);
      this.query.fromDate = dates[0];
      this.query.toDate = dates[1];
    },
    showFilter() {
      this.$refs.filterModal.show(this.query);
    },
    onSearch(query) {
      this.query = { ...query };
      this.index();
    },
    onChangeDate() {
      this.calculateDate();
      this.index();
    },
    index() {
      this.loading = true;
      this.updateQuerySting();

      if (typeof this.query.regionIds == "string") {
        this.query.regionIds = [this.query.regionIds];
      }
      if (typeof this.query.salespersonIds == "string") {
        this.query.salespersonIds = [this.query.salespersonIds];
      }
      DashboardRepository.callPlanDashbaord(this.query)
        .then((response) => {
          const data = response?.data?.data;
          this.data = { ...data };
        })
        .catch((error) => {
          this.loading = false;
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>