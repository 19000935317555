<template>
  <b-modal
    cancel-variant="outline-secondary"
    :cancel-title="$t('button.close')"
    centered
    hide-footer
    no-close-on-backdrop
    size="lg"
    v-model="isShow"
  >
    <div class="mb-1">
      <n-search-input
        :key="`search-${key}`"
        ref="search"
        :fields="fields"
        v-model="query"
      >
      </n-search-input>
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button type="button" variant="primary" @click="serach">
            {{ $t("button.search") }}
          </b-button>

          <b-button class="ml-1" variant="outline-secondary" @click="reset">
            {{ $t("button.reset") }}
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { BModal, BButton, BRow, BCol, BFormGroup } from "bootstrap-vue";
import NSearchInput from "@/components/NSearchInput";
import SearchInput from "./searchInput";
import moment from "moment";
import { calculateDatesByPeriod } from "@/libs/helper";

export default {
  components: {
    BModal,
    BButton,
    BRow,
    BCol,
    BFormGroup,

    NSearchInput,
  },
  watch: {
    // "query.fromDate"(value) {
    //   this.query.toDate = value;
    // },
    // "query.year"(value) {
    //   const date = `${value}-01-01`;
    //   this.query.fromDate = date;
    //   this.query.toDate = moment(date).endOf("year").format("YYYY-MM-DD");
    // },
  },
  data() {
    return {
      key: 1,
      fields: [],
      isShow: false,
      query: {
        year: null,
        month: null,
        date: null,
        fromDate: null,
        regionIds: [],
      },
    };
  },
  methods: {
    show(query) {
      this.query = {
        year: null,
        month: null,
        date: null,
        ...query,
      };
      this.checkFields();
      this.isShow = true;
    },
    hide() {
      this.isShow = false;
    },
    checkFields() {
      let fields = [...SearchInput];
      if (this.query.period == "dtd") {
        this.query.date = this.query.fromDate;
        fields.push({
          key: "date",
          label: "field.date",
          type: "date-picker",
          disableType: "after",
          md: 12,
          lg: 12,
        });
      } else if (this.query.period == "mtd") {
        this.query.year = moment(this.query.fromDate).format("YYYY");
        this.query.month = moment(this.query.fromDate).format("MM");
        fields.push(
          {
            key: "month",
            label: "field.month",
            selectionKey: "value",
            selectionLabel: "text",
            type: "single-selection",
            translatable: true,
            options: [
              { text: "month.january", value: "01" },
              { text: "month.february", value: "02" },
              { text: "month.march", value: "03" },
              { text: "month.april", value: "04" },
              { text: "month.may", value: "05" },
              { text: "month.june", value: "06" },
              { text: "month.july", value: "07" },
              { text: "month.august", value: "08" },
              { text: "month.september", value: "09" },
              { text: "month.october", value: "10" },
              { text: "month.november", value: "11" },
              { text: "month.december", value: "12" },
            ],
            md: 6,
            lg: 6,
          },
          {
            key: "year",
            label: "field.year",
            type: "year-picker",
            disableType: "after",
            md: 6,
            lg: 6,
          }
        );
      } else if (this.query.period == "ytd") {
        this.query.year = moment(this.query.fromDate).format("YYYY");
        fields.push({
          key: "year",
          label: "field.year",
          type: "year-picker",
          disableType: "after",
          md: 12,
          lg: 12,
        });
      }

      this.fields = [...fields];
    },
    serach() {
      if (this.query.period == "dtd") {
        this.query.fromDate = this.query.date;
        this.query.toDate = this.query.date;
      } else if (this.query.period == "mtd") {
        this.query.fromDate = `${this.query.year}-${this.query.month}-01`;
        this.query.toDate = moment(this.query.fromDate)
          .endOf("month")
          .format("YYYY-MM-DD");
      } else if (this.query.period == "ytd") {
        this.query.fromDate = `${this.query.year}-01-01`;
        this.query.toDate = moment(this.query.fromDate)
          .endOf("year")
          .format("YYYY-MM-DD");
      }
      this.$emit("search", this.query);
      this.hide();
    },
    reset() {
      this.calculateDate();
      this.checkFields();
      this.query = {
        ...this.query,
        regionIds: [],
        salespersonIds: [],
      };
      this.key++;
    },
    calculateDate() {
      let type = "today";
      if (this.query.period == "ytd") {
        type = "thisYear";
      } else if (this.query.period == "mtd") {
        type = "thisMonth";
      } else if (this.query.period == "wtd") {
        type = "thisWeek";
      }
      const dates = calculateDatesByPeriod(type);
      this.query.fromDate = dates[0];
      this.query.toDate = dates[1];
    },
  },
};
</script>