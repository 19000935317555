<template>
  <b-card v-if="data" no-body class="sales-volume-card">
    <b-overlay :show="loading" rounded="sm" variant="light">
      <b-card-header class="pb-2">
        <b-card-title>{{ $t("field.salesVolume") }}</b-card-title>
        <b-dropdown
          no-caret
          right
          variant="outline-secondary"
          class="chart-dropdown"
          toggle-class="p-50 text-capitalize"
          size="sm"
        >
          <template #button-content>
            {{ $t(`state.${value}`) }}
            <feather-icon icon="ChevronDownIcon" />
          </template>
          <b-dropdown-item
            v-for="state in states"
            :key="state"
            class="text-capitalize"
            @click="onChange(state)"
          >
            {{ $t(`state.${state}`) }}
          </b-dropdown-item>
        </b-dropdown>
      </b-card-header>

      <b-card-body>
        <b-row>
          <b-col cols="6" class="text-center target">
            <small>
              {{ $t("field.archieved") }} :
              <span class="text-primary">
                {{ data.achievedTargetRate | number }}%</span
              >
            </small>
            <h1 class="font-large-2 font-weight-bolder mb-0 text-primary">
              {{ data.salesVolume | number }}
            </h1>
            <small> {{ $t("field.salesVolume") }} </small>

            <small class="mt-2 d-block">
              {{ $t("field.target") }}
            </small>
            <small class="text-primary d-block">
              {{ data.salesTarget | number }}
            </small>
          </b-col>

          <b-col cols="6" class="text-center">
            <small>
              {{ $t("field.order") }} :
              <span class="text-primary"> {{ data.totalOrder | number }}</span>
            </small>
            <h1 class="font-large-2 font-weight-bolder mb-0 text-primary">
              {{ data.skuPerOrder | number }}
            </h1>
            <small> {{ $t("field.skuPerOrder") }} </small>

            <small class="mt-2 d-block">
              {{ $t("field.totalSkuPerOrder") }}
            </small>
            <small class="text-primary d-block">
              {{ data.totalSku | number }}
            </small>
          </b-col>
        </b-row>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BRow,
  BCol,
  BCardText,
  BOverlay,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BOverlay,
  },
  props: {
    value: {},
    data: {
      type: Object,
      default: () => {},
    },
    states: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      supportTrackerRadialBar: {
        chartOptions: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: "65%",
              },
              track: {
                background: "#ebf2ff",
                strokeWidth: "100%",
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: "#5e5873",
                  fontSize: "1rem",
                },
                value: {
                  offsetY: 15,
                  color: "#5e5873",
                  fontSize: "1.714rem",
                },
              },
            },
          },
          colors: [$themeColors.danger],
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: [""],
        },
      },
    };
  },
  methods: {
    onChange(state) {
      this.$emit("input", state);
      this.$emit("change");
    },
  },
};
</script>
