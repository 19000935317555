<template>
  <b-card v-if="data" no-body class="card-statistics">
    <b-overlay :show="loading" rounded="sm" variant="light">
      <b-card-body class="statistics-body">
        <b-row>
          <b-col cols="12" md="4" class="pb-2 pb-md-0">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-primary">
                  <feather-icon size="24" icon="ShoppingCartIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0 text-primary">
                  {{ data.unscheduledOrderRate }}%
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ $t("field.unscheduledOrder") }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col cols="12" md="4" class="pb-2 pb-md-0">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-primary">
                  <feather-icon size="24" icon="ShoppingCartIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0 text-primary">
                  {{ data.unscheduledOrder | number }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ $t("field.unscheduledOrder") }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col cols="12" md="4">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-primary">
                  <feather-icon size="24" icon="UsersIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0 text-primary">
                  {{ data.activeOutlet | number }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ $t("field.activeOutlet") }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BOverlay,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BOverlay,
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
